import React, {ReactNode} from 'react';
import {AppBar, Box, FormControl, MenuItem, Select, Toolbar} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useStore} from "../store/useStore";

type LayoutProps = {
    children: ReactNode
}

const Layout = ({children}:LayoutProps) => {
    const location = useLocation();
    const fridgeId = useStore(state => state.fridgeId);

    const lang = useStore(state => state.lang);
    const setLang = useStore(state => state.setLang);

    const handleChange = (event: any) => {
        setLang(event.target.value);
    };
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" color={'transparent'} style={{boxShadow: 'none', marginBottom: '32px'}}>
                <Toolbar sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                    {location?.pathname === `/${fridgeId}/cart` ? <Link to={`/${fridgeId}`} style={{
                        background: '#F4F4F4',
                        boxShadow: '0 4px 15px #00000050',
                        borderRadius: '100px',
                        width: '42px',
                        height: '42px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: '700',
                        textDecoration: 'none',
                        fontSize: 24,
                        color: '#FFFFFF'
                    }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12L19 12M5 12L11 6M5 12L11 18" stroke="#000000" strokeWidth="2"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </Link>: null}


                    <Link to={`/${fridgeId}`} style={{
                        background: '#FFB839',
                        padding: '4px 16px',
                        borderRadius: '100px',
                        fontWeight: '700',
                        textDecoration: 'none',
                        fontSize: 24,
                        color: '#FFFFFF'
                    }}>VBox</Link>


                    <FormControl sx={{m: 1, minWidth: 50}}>
                        <Select
                            value={lang}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{
                                bgcolor: '#F4F4F4',
                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                                '& .MuiOutlinedInput-input': {p: 1.5},
                                borderRadius: '40px',
                                fontWeight: '700',
                                '& .MuiOutlinedInput-input:focused': {
                                    borderColor: 'transparent',
                                }
                            }}
                        >

                            <MenuItem value={'Ru'}>Ru</MenuItem>
                            <MenuItem value={'Kz'}>Kz</MenuItem>
                        </Select>
                    </FormControl>
                </Toolbar>
            </AppBar>
            {children}
        </Box>
    );
};

export default Layout;