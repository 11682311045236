import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, Container, Skeleton} from "@mui/material";
import {searchGood, useStore} from "../store/useStore";
import Card from "../components/card";
import CartButton from "../components/cartButton";
import TypeProductButton from "../components/typeProductButton";

const Dashboard = () => {
    const [isSearch, setIsSearch] = useState(false)
    const [inputValue, setInputValue] = useState<string>('');
    const [debouncedValue, setDebouncedValue] = useState<string>('');
    const goods = useStore(state => state.goods);
    const fridgeId = useStore(state => state.fridgeId);
    const searchGoods = useStore(state => state.searchGoods);
    const fetchSearch = useStore(state => state.fetchSearch);
    const activeProductType = useStore(state => state.activeProductType);
    const setActiveProductType = useStore(state => state.setActiveProductType);
    const getGoods = useStore(state => state.getGoods);
    const productTypes = useStore(state => state.productTypes);
    const fetchProductTypes = useStore(state => state.fetchProductTypes);
    const getCartGoods = useStore(state => state.getCartGoods);
    const getGoodQuantityInCart = useStore(state => state.getGoodQuantityInCart);
    const setProductId = useStore(state => state.setProductId);
    const productId = useStore(state => state.productId);
    const setSearchDataNull = useStore(state => state.setSearchDataNull)


    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    useEffect(() => {
        if (debouncedValue && fridgeId) {
            fetchSearch({fridgeId:fridgeId, searching:`${debouncedValue}`});
        }
    }, [debouncedValue, fridgeId]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleFindElement = () => {
        const element = document.getElementById(productId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        setTimeout(() => setProductId(''), 2000); // Highlight for 2 seconds
    };

    const handleProductClick = (good: searchGood) => {
        setSearchDataNull()
        setProductId(`${good.id}`)
        setActiveProductType(`${good.type_id}`)
        setIsSearch(false)
        setInputValue('')
        setDebouncedValue('')
    }

    useEffect(() => {
        if(goods[0] && productId && fridgeId){
            handleFindElement()
        }
    }, [goods, productId, fridgeId]);

    useEffect(() => {
        if (activeProductType && fridgeId) {
            getGoods(activeProductType, fridgeId);
            getCartGoods();
            getGoodQuantityInCart();
        } else {
            fetchProductTypes(fridgeId);
        }
    }, [activeProductType, fridgeId]);

    useEffect(() => {
        if (activeProductType && fridgeId) {
            getGoods(activeProductType, fridgeId);
            getCartGoods();
            getGoodQuantityInCart();
        } else {
            fetchProductTypes(fridgeId);
        }
    }, [activeProductType, fridgeId]);

    return (
        <>
            <Container>
                {/* Search */}
                <Box sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    px: '15px',
                    py: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    bg: '#F4F4F4',
                    boxShadow: '0px 0px 60px #00000050',
                    borderRadius: '20px',
                    mb: '20px',
                    position:'relative'
                }}>
                    <svg width="28" height="28" style={{marginRight: '10px', outline: 'none'}} viewBox="0 0 24 24"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C2.99933 7.684 3.62867 6.14667 4.888 4.888C6.14733 3.62933 7.68467 3 9.5 3C11.3153 3 12.853 3.62933 14.113 4.888C15.373 6.14667 16.002 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5633 11.8133 14.0007 10.7507 14 9.5C13.9993 8.24933 13.562 7.187 12.688 6.313C11.814 5.439 10.7513 5.00133 9.5 5C8.24867 4.99867 7.18633 5.43633 6.313 6.313C5.43967 7.18967 5.002 8.252 5 9.5C4.998 10.748 5.43567 11.8107 6.313 12.688C7.19033 13.5653 8.25267 14.0027 9.5 14Z"
                            fill="black"/>
                    </svg>

                    <input value={inputValue} onChange={handleChange} type="text" placeholder={'Поиск'} style={{border: 'none', fontSize: '18px', outline: 'none'}} onClick={() => {setIsSearch(true)}}/>

                    {isSearch ?(
                            <button onClick={() => {
                                setSearchDataNull()
                                setIsSearch(false)
                                setProductId('')
                                setInputValue('')
                            }} style={{boxSizing: 'border-box', border: 'none', background: 'transparent', height: 'fit-content', display: 'flex', alignItems:'center', marginLeft: 'auto'}}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.75806 17.243L12.0011 12L17.2441 17.243M17.2441 6.757L12.0001 12L6.75806 6.757"
                                          stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                    )
                        : null

                    }
                    {/*search on click*/}
                    {isSearch ? (
                        <Box sx={{
                            position: 'absolute',
                            width: '95%',
                            height: '300px',
                            left: '0',
                            top: '110%',
                            zIndex:'100',
                            bgcolor: '#FFFFFF',
                            boxShadow: '5px 5px 60px #00000050',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            padding: '15px 10px',
                            overflowY: 'scroll',
                        }}>
                            {searchGoods.goods.map(good => (
                                <div onClick={() => {
                                    handleProductClick(good)
                                }} style={{display: 'flex', boxSizing: 'border-box', width: '100%',  background: '#FFFFFF', boxShadow: '0px 5px 60px #00000030', padding: '5px 10px', borderRadius: '5px',   justifyContent: 'space-between', alignItems: 'center'}}>
                                    <p style={{fontSize: '16px', height: 'fit-content', margin: '0', width: '35%', fontWeight: '700'}}>{good.name}</p>
                                    <Box sx={{borderRadius: '100px', marginLeft: 'auto', padding: '5px 10px', bgcolor: '#FFB839', height: 'fit-content'}} >
                                        <p style={{fontSize: '12px', fontWeight: '500', margin: '0'}}>{good.type_name}</p>
                                    </Box>
                                </div>
                            ))}

                        </Box>
                    ) : null}
                </Box>
                {/* Tabs */}
                <Box sx={{
                    maxWidth: '100%',
                    height: '56px',
                    boxSizing: 'border-box',
                    display: 'flex',
                    px: 1,
                    alignItems: 'center',
                    gap: 2,
                    mb: 3,
                    overflowX: 'auto'
                }}>
                    {productTypes.length > 0 ? productTypes.map(productType => (
                        <TypeProductButton key={productType.id} id={productType.id} name={productType.name} discount={productType.discount}/>
                    )) : (
                        <>
                            <Skeleton variant={"rounded"} sx={{borderRadius: '100px'}} width={100} height={36}/>
                            <Skeleton variant={"rounded"} sx={{borderRadius: '100px'}} width={100} height={36}/>
                            <Skeleton variant={"rounded"} sx={{borderRadius: '100px'}} width={100} height={36}/>
                        </>
                    )}
                </Box>
                {/* Cards */}
                <Box sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    marginBottom: '6rem'
                }}>
                    {goods.length > 0 ? goods.map(good => (
                        <Card key={good.id} {...good} />
                    )) : (
                        <div style={{display: 'flex', flexDirection: 'column', gap: '45px'}}>
                            <div style={{display: "flex", gap: 10}}>
                                <Skeleton variant={"rounded"} sx={{borderRadius: '12px', width: '100%'}} width={'80px'}
                                          height={80}/>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '45%'
                                }}>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'80%'} height={16}/>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'50%'} height={16}/>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'100%'} height={16}/>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '25%'
                                }}>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '12px', width: '100%'}}
                                              width={'100%'} height={16}/>
                                </div>
                            </div>
                            <div style={{display: "flex", gap: 10}}>
                                <Skeleton variant={"rounded"} sx={{borderRadius: '12px', width: '100%'}} width={'80px'}
                                          height={80}/>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '45%'
                                }}>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'80%'} height={16}/>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'50%'} height={16}/>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'100%'} height={16}/>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '25%'
                                }}>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '12px', width: '100%'}}
                                              width={'100%'} height={16}/>
                                </div>
                            </div>
                            <div style={{display: "flex", gap: 10}}>
                                <Skeleton variant={"rounded"} sx={{borderRadius: '12px', width: '100%'}} width={'80px'}
                                          height={80}/>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '45%'
                                }}>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'80%'} height={16}/>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'50%'} height={16}/>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '100px', width: '100%'}}
                                              width={'100%'} height={16}/>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '25%'
                                }}>
                                    <Skeleton variant={"rounded"} sx={{borderRadius: '12px', width: '100%'}}
                                              width={'100%'} height={16}/>
                                </div>
                            </div>
                        </div>
                    )}
                </Box>

            </Container>
            {/* Static button for open cart page */}
            <CartButton/>
        </>
    );
};

export default Dashboard;
