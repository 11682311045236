import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import {useStore} from "../store/useStore";
import {useParams} from "react-router-dom";

const Billing = () => {
    const {id} = useParams()
    const cart = useStore(state => state.cart)
    const postOrder = useStore(state => state.postOrder)
    const getCartGoods = useStore(state => state.getCartGoods)

    useEffect(() => {
        getCartGoods()
    }, []);

    const handleKaspi = () => {
        postOrder({account: Number(id), goods: cart.goods.map(good => ({id: good.id, quantity: good.quantity}))})
    }
    return (
        <div>
            <Container>
                <Box sx={{color: '#00000080', mb: '15px'}}>
                    <p style={{margin: '0 0 5px 0', fontWeight: '600', fontSize: 16}}>Стоимость товаров:</p>
                    <p style={{margin: 0, fontSize: 16, fontWeight: '700',}}>{cart.total} тг</p>
                </Box>
                { cart.discount_total ?
                    <Box sx={{color: '#00000080', mb: '15px'}}>
                        <p style={{margin: '0 0 5px 0', fontWeight: '600', fontSize: 16}}>Скидки:</p>
                        <p style={{margin: 0, fontSize: 16, fontWeight: '700',}}>{cart.discount_total} тг</p>
                    </Box> : null
                }

            </Container>

            <Box sx={{
                color: '#FFFFFF',
                mb: '15px',
                bgcolor: '#FFB839',
                width: '30%',
                p: '12px 14px',
                borderBottomRightRadius: '12px',
                borderTopRightRadius: '12px'
            }}>
                <p style={{margin: '0 0 5px 0', fontWeight: '600', fontSize: 16}}>Итого:</p>
                <p style={{margin: 0, fontSize: 16, fontWeight: '700',}}>{cart.total} тг</p>
            </Box>

            <Container>
                <h4 style={{fontWeight: '700', fontSize: 18, marginBottom: '24px'}}>Выберите способ оплаты:</h4>
                <button style={{
                    width: '100%',
                    borderRadius: '15px',
                    background: '#F14635',
                    padding: '12px 14px',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '14px'
                }} onClick={() => handleKaspi()}>
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1549 1550" width="48"
                         height="48" style={{fill: "#ffffff"}}>
                        <title>Logo (49)-svg</title>
                        <path id="Layer" fillRule="evenodd"
                              d="m723.6 847.2c-51.6 44-50.7 166.5-49.6 308.6l0.2 40.3 0.1 15.5c0.6 134.3 2.5 249.2 34.2 300.7 10.5 17.1 30.3 29.6 56.6 37.1 6.8 0.2 13.6 0.5 20.5 0.5 65.2 0 128.5-8 189-23 25.1-12 43.2-26.5 49.4-41.6 3.7-9.8 5.8-27.7 5.8-50.9 0-93-27.6-271.1-40.8-356.9l-6.7-45-1.3-8.9-1.9-13c-11.2-76.4-29.9-158.4-168.5-179.8-38.6-6.1-67-0.5-87 16.4zm-245.7 576.2c4.6 35.9 11.9 63.2 21.5 80.6 14.3 5.8 28.9 11.2 43.7 16.1 13-11.5 21.2-43.6 18.5-121.7-3.9-112.1-23.2-259.9-53.2-258.2-32.4 2-44.7 170.6-30.5 283.2zm658.7-277.3c-36.3 18-22.2 224.5 15.2 290.3 3.9 6.6 11.9 7.7 20.9 6.2 28.3-17 55.3-36 80.9-56.6 3.3-6.7 5.9-14.6 7.8-24.9 7-35.7-77.6-237.8-124.8-215zm-1136.6-378.2c0 257.2 127.8 484.7 323.8 624 5.2-6 9.1-21.9 12.4-35.1l1-5.3 4.2-18.8c15.9-74.6 19-147.7 10.2-234.2-5.5-55.9-14.1-89.3-22.2-118.4-8.2-31.6-15.2-58.9-14.6-101.3 0.5-38.3 8.4-64.8 17-93 9.9-32.1 21-68.4 22.6-131.4 4.2-122.2 16.9-152.6 53.6-188.1 36.5-35.3 47.7-50 47.7-76 0-25.9-34.5-85.1 26.2-147.4 39.1-39.8 77.1-54.3 104.4-54.3 15.4 0 27.5 4.6 34.5 11.9 19.5 20.3 14 59.7-24.9 111.8-38.9 52.4-37.7 75.2-35.4 87.2 2.4 12.2 14.3 28.5 37.1 46.2 17.4 14.1 37.6 30.1 50.6 53.2 24.6 43.7 38.8 85.5 58.5 143.2l5.5 16.4 2.5 6.4c19.2 45.7 65.6 61.9 145.6 61.9 83.1 0 171.3-54 171.3-95.6 0-41.3-17.2-46.4-17.2-81.9 0-35.5 53.3-137.9 133.7-137.9 41 0 62.3 29.6 42.5 70.5-19.4 40.5-58.7 98.3-58.7 123.8 0 47.2 70.6 10.7 114.9-25.5 45.1-36.3 55-46.4 86-100.6 18.8-33.5 45.7-39.9 63.5-31.1 19.8 9.8 32.3 38.9 14.4 80.5-14 32.3-52.1 73.3-92.2 116.8-53.8 57.9-114.5 123.7-114.5 165.9 0 214.9 121.7 402.4 135.8 423 13.7 20.4 21.2 27.2 25.3 29.2 113.8-133 182.9-304.9 183.7-492.7v-6.7l-0.2-14.3c-9.4-415.9-352.4-750.1-774.2-750.1-427.7 0-774.4 343.8-774.4 767.8z"/>
                    </svg>
                    <span style={{fontWeight: '700', fontSize: '18px', margin: '0', color: '#FFFFFF'}}>Kaspi.kz</span>
                </button>
                <button style={{
                    width: '100%',
                    borderRadius: '15px',
                    background: '#00926E',
                    padding: '12px 14px',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                }}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={48} height={48}
                         xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 95 95"
                         xmlSpace="preserve">
                        <g>
                            <g transform="translate(-152 -64)">
                                <g>
                                    <g>
                                        <path id="_x38_qbda" style={{fill: '#00805F'}} className="st0" d="M160.4,111.5l39.1,39.1l39.1-39.1l-39.1-39.1L160.4,111.5z M171.8,128.6l-4-4l-13.1-13.1
					l13.1-13.1l4-4V83.8h10.6l17-17.1l13.1,13.1l4,4h10.6v10.6l4,4l13.1,13.1l-13.1,13.1l-4,4v10.6h-10.6l-4,4l-13.1,13.1
					l-13.1-13.1l-4-4h-10.6V128.6z M199.5,64l-15.9,15.9h-15.7v15.7L152,111.5l15.9,15.9v15.7h15.7l15.9,15.9l15.9-15.9h15.7v-15.7
					l15.9-15.9l-15.9-15.9V79.9h-15.7L199.5,64z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path id="_x38_qbdb" className="st1" fill={'#FAAE17'} d="M154.8,111.5l17-17V83.8h10.6l17-17l17,17h10.6v10.6l17,17l-17,17v10.6h-10.6l-17,17l-17-17
					h-10.6v-10.6L154.8,111.5z M171.8,123c6.6-8,16.6-13,27.7-13c8.6,0,15.6-7,15.6-15.6c0-4-1.6-7.7-4.1-10.5l-11.5-11.5L188,83.8
					L171.8,100l-11.5,11.5L171.8,123L171.8,123z M227.2,100.1c-6.6,8-16.6,13-27.7,13c-8.6,0-15.6,7-15.6,15.6c0,4,1.6,7.7,4.1,10.5
					h0l11.5,11.5l11.5-11.5l16.2-16.2l11.5-11.5L227.2,100.1z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span style={{fontWeight: '700', fontSize: '18px', margin: '0', color: '#FFFFFF'}}>Halyk Bank</span>
                </button>
            </Container>
        </div>
    );
};

export default Billing;