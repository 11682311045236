import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import {useStore} from "../store/useStore";
import Card from "../components/card";
import {useNavigate} from "react-router-dom";

const Cart = () => {
    const navigate = useNavigate()
    const fridgeId = useStore(state => state.fridgeId);
    const cart = useStore(state => state.cart)

    return (
        <>
            <Container>
                {/*cards*/}
                <Box sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    marginBottom: '6rem'
                }}>
                    {/*card*/}
                    {
                        cart.goods.map(good => (
                            <Card key={good.id} name_kz={good.name_kz} id={good.id} image={good.image} name={good.name}
                                  description={good.description} quantity={good.quantity} price={good.price} originalPrice={good.originalPrice}/>
                        ))
                    }
                </Box>

            </Container>
            <button
                onClick={() => navigate(`/${fridgeId}/billing`)}
                style={{
                    backgroundColor: '#FFB839', padding: '12px 0', width: '90%', borderRadius: '12px',
                    position: 'fixed',
                    bottom: '25px',
                    right: '5%',
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 5
                }}>
                <span style={{color: '#FFFFFF', fontWeight: '600', fontSize: 20}}>Перейти к оплате</span>
                <span style={{color: '#FFFFFF', fontWeight: '500'}}>{cart.total}тг</span>
            </button>
        </>
    );
};

export default Cart;