import React from 'react';
import {Box, Button} from "@mui/material";
import {ProductType, useStore} from "../store/useStore";


const TypeProductButton = ({id, name, discount}: ProductType) => {
    const activeProductType = useStore(state => state.activeProductType)
    const setActiveProductType = useStore(state => state.setActiveProductType)
    return (
        <Box sx={{
            position: 'relative'
        }}>
            <Button key={id} onClick={() => {
                setActiveProductType(String(id))
            }} variant="contained" sx={{
                boxSizing: 'content-box',
                bgcolor: activeProductType === String(id) ? '#FFB839' : '#F4F4F4',
                color: activeProductType === String(id) ? '#FFFFFF' : '#000000',
                fontWeight: '600',
                textTransform: 'capitalize',
                borderRadius: '100px',
                minWidth: 'auto',
                '&:hover': {
                    backgroundColor: activeProductType === String(id) ? '#FFB839' : '#F4F4F4',
                }
            }}><span style={{whiteSpace: 'nowrap'}}>{name}</span></Button>
            {discount ? (
                <Box sx={{
                    border: '2px solid #FFFFFF',
                    position: 'absolute',
                    right: '0px',
                    top: '-8px',
                    padding: '3px 5px',
                    bgcolor: activeProductType === String(id) ? '#FFB839' : '#F4F4F4',
                    borderRadius: '100px',
                    margin: '0',
                    color: activeProductType === String(id) ? '#FFFFFF' : '#F16666',
                }}>
                    <p style={{fontSize: '9px', fontWeight: '700', margin: '0', lineHeight: '9px',}}>-{Number(discount.discount_amount)}%</p>
                </Box>
            ) : null}
        </Box>
    );
};

export default TypeProductButton;