import React, {useEffect} from 'react';
import './App.css';
import Layout from "./components/layout";
import {Outlet, useParams} from "react-router-dom";
import {useStore} from "./store/useStore";

function App() {
    const {id} = useParams()
    const setFridgeId = useStore(state => state.setFridgeId)

    useEffect(() => {
        if(id){
            setFridgeId(id)
        }

    }, [id]);
    return (
        <Layout>
            <Outlet/>
        </Layout>
    );
}

export default App;
